<template>
  <div class="view-categories">
    <div class="header-spacing"></div>

    <div class="container categories d-flex align-items-center flex-column flex-md-row">
      <h6 class="mr-0 mr-md-3 mb-3 mb-md-0">Ver conteúdo por categoria:</h6>

      <action-dispatcher action="contents/getCategories">
        <b-dropdown toggle-class="rounded-pill select" :text="dropdownPlaceholder">
          <b-dropdown-item
            v-for="cat in categories"
            :key="cat.id"
            @click="selectCategory(cat)"
          >
            {{ cat.name }}
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="selectCategory(null)">
            Todas as categorias
          </b-dropdown-item>
        </b-dropdown>
      </action-dispatcher>
    </div>

    <div class="container-fluid py-5">
      <div v-if="loadingContent" class="d-flex justify-content-center">
        <loading-spinner />
      </div>

      <div v-else-if="category && categoryContents.length > 0" class="container">
        <div class="row">
          <div class="col-xl-1-5 col-lg-3 col-md-4 col-6" v-for="(item, index) in categoryContents" :key="index">
            <carousel-media :item="item" />
          </div>
        </div>
      </div>

      <div v-else class="container">
        <action-dispatcher
          v-for="cat in categories"
          :key="cat.id"
          action="contents/getCategoryContents"
          :parameters="cat.id"
        >
          <template v-slot:default="slotProps">
            <carousel-wrapper :items="slotProps.data" :title="cat.name" />
          </template>
        </action-dispatcher>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselMedia from '@/components/CarouselMedia'
import CarouselWrapper from '@/components/CarouselWrapper'
import { getRandomVideoThumb } from '@/functions/helpers'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  name: 'home',
  components: {
    CarouselMedia,
    CarouselWrapper
  },
  data () {
    return {
      category: null,
      loadingContent: false
    }
  },
  computed: {
    thumb () {
      return getRandomVideoThumb()
    },
    dropdownPlaceholder () {
      return get(this.category, 'name', 'Selecione uma categoria')
    },
    ...mapState('contents', ['categories', 'categoryContents'])
  },
  methods: {
    selectCategory (cat) {
      this.category = cat
      if (cat) {
        this.loadContent()
      }
    },
    loadContent () {
      this.loadingContent = true
      this.$store.dispatch('contents/getCategoryContents', this.category.id)
        .finally(() => {
          this.loadingContent = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-categories {
  .header-buttons {
    ::v-deep .like-button {
      font-size: 28px !important;
    }
  }

  .categories {
    width: 100%;

    h6 {
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;

      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 21px;
      }
    }

    ::v-deep .select {
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.6);
      color: rgba(255, 255, 255, 0.6);
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
    }
  }

  ::v-deep .dropdown-menu {
    background: #212631;
    border-radius: 8px;
    width: 300px;

    a {
      color: white;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }

    hr {
      border-top-color: rgba(255, 255, 255, 0.05);
    }
  }
}
</style>
